import React from 'react';

function TextIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M15.32 12.61L12.93 6.60999C12.8563 6.42347 12.7282 6.26343 12.5623 6.15064C12.3965 6.03786 12.2006 5.97755 12 5.97755C11.7994 5.97755 11.6035 6.03786 11.4377 6.15064C11.2718 6.26343 11.1437 6.42347 11.07 6.60999L8.68 12.61L7.07 16.61C6.97187 16.8566 6.97574 17.1322 7.08076 17.376C7.18578 17.6198 7.38335 17.8119 7.63 17.91C7.87665 18.0081 8.15218 18.0043 8.39598 17.8992C8.63978 17.7942 8.83187 17.5966 8.93 17.35L10.28 14H13.73L15.08 17.37C15.1286 17.4921 15.2008 17.6035 15.2924 17.6977C15.384 17.792 15.4933 17.8672 15.614 17.9192C15.7347 17.9712 15.8645 17.9989 15.9959 18.0008C16.1274 18.0026 16.2579 17.9786 16.38 17.93C16.5021 17.8814 16.6135 17.8092 16.7077 17.7176C16.802 17.626 16.8772 17.5167 16.9292 17.396C16.9812 17.2753 17.009 17.1455 17.0108 17.0141C17.0126 16.8826 16.9886 16.7521 16.94 16.63L15.32 12.61ZM11.08 12L12 9.68999L12.92 12H11.08Z"
        fill="currentColor"
      />
      <path
        d="M22 0H20C19.4696 0 18.9609 0.210714 18.5858 0.585786C18.2107 0.960859 18 1.46957 18 2H6C6 1.46957 5.78929 0.960859 5.41421 0.585786C5.03914 0.210714 4.53043 0 4 0L2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 4C0 4.53043 0.210714 5.03914 0.585786 5.41421C0.960859 5.78929 1.46957 6 2 6V18C1.46957 18 0.960859 18.2107 0.585786 18.5858C0.210714 18.9609 0 19.4696 0 20L0 22C0 22.5304 0.210714 23.0391 0.585786 23.4142C0.960859 23.7893 1.46957 24 2 24H4C4.53043 24 5.03914 23.7893 5.41421 23.4142C5.78929 23.0391 6 22.5304 6 22H18C18 22.5304 18.2107 23.0391 18.5858 23.4142C18.9609 23.7893 19.4696 24 20 24H22C22.5304 24 23.0391 23.7893 23.4142 23.4142C23.7893 23.0391 24 22.5304 24 22V20C24 19.4696 23.7893 18.9609 23.4142 18.5858C23.0391 18.2107 22.5304 18 22 18V6C22.5304 6 23.0391 5.78929 23.4142 5.41421C23.7893 5.03914 24 4.53043 24 4V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0V0ZM2 2H4V3V4H2V2ZM2 22V20H4V21V22H2ZM22 22H20V20H22V22ZM20 18C19.4696 18 18.9609 18.2107 18.5858 18.5858C18.2107 18.9609 18 19.4696 18 20H6C6 19.4696 5.78929 18.9609 5.41421 18.5858C5.03914 18.2107 4.53043 18 4 18V6C4.53043 6 5.03914 5.78929 5.41421 5.41421C5.78929 5.03914 6 4.53043 6 4H18C18 4.53043 18.2107 5.03914 18.5858 5.41421C18.9609 5.78929 19.4696 6 20 6V18ZM22 4H20V2H22V4Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default TextIcon;

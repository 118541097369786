import React from 'react';

function WaveformIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 25 23" {...props}>
      <path
        d="M1.646 8.79863C1.13633 8.79863 0.723 9.21196 0.723 9.72162V13.2574C0.723 13.7671 1.13633 14.1804 1.646 14.1804C2.15566 14.1804 2.56899 13.7671 2.56899 13.2574V9.72162C2.56899 9.21196 2.15566 8.79863 1.646 8.79863Z"
        fill="currentColor"
      />
      <path
        d="M5.3384 6.40308C4.82874 6.40308 4.41541 6.81642 4.41541 7.32608V15.6526C4.41541 16.1622 4.82874 16.5756 5.3384 16.5756C5.84806 16.5756 6.2614 16.1622 6.2614 15.6526V7.32608C6.2614 6.81642 5.84806 6.40308 5.3384 6.40308Z"
        fill="currentColor"
      />
      <path
        d="M9.03038 2.82399C8.52072 2.82399 8.10739 3.23732 8.10739 3.74698V19.2317C8.10739 19.7413 8.52072 20.1547 9.03038 20.1547C9.54005 20.1547 9.95338 19.7413 9.95338 19.2317V3.74741C9.95338 3.23732 9.54047 2.82399 9.03038 2.82399Z"
        fill="currentColor"
      />
      <path
        d="M12.7228 4.36274C12.2131 4.36274 11.7998 4.77607 11.7998 5.28573V17.6933C11.7998 18.203 12.2131 18.6163 12.7228 18.6163C13.2325 18.6163 13.6458 18.203 13.6458 17.6933V5.28573C13.6458 4.77607 13.2329 4.36274 12.7228 4.36274Z"
        fill="currentColor"
      />
      <path
        d="M16.4152 0.0550003C15.9055 0.0550003 15.4922 0.468332 15.4922 0.977995V22.0011C15.4922 22.5107 15.9055 22.9241 16.4152 22.9241C16.9249 22.9241 17.3382 22.5107 17.3382 22.0011V0.977995C17.3382 0.467908 16.9249 0.0550003 16.4152 0.0550003Z"
        fill="currentColor"
      />
      <path
        d="M20.1076 4.6704C19.5979 4.6704 19.1846 5.08373 19.1846 5.5934V17.3857C19.1846 17.8953 19.5979 18.3087 20.1076 18.3087C20.6173 18.3087 21.0306 17.8953 21.0306 17.3857V5.5934C21.0306 5.08331 20.6173 4.6704 20.1076 4.6704Z"
        fill="currentColor"
      />
      <path
        d="M23.8 9.33673C23.2903 9.33673 22.877 9.75006 22.877 10.2597V12.7185C22.877 13.2282 23.2903 13.6415 23.8 13.6415C24.3097 13.6415 24.723 13.2282 24.723 12.7185V10.2597C24.723 9.75049 24.3097 9.33673 23.8 9.33673Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default WaveformIcon;
